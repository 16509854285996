<template>
    <div class="container" style="margin-top:125px;">
        <main>
            <div class="amounts">
                <div class="title" style="text-align: center;margin-top: -40px;"><sapn @click="clauses(1)" v-bind:class="{'clauses':clause}" style="cursor:pointer;">條款及細則</sapn>&nbsp;&nbsp;&nbsp;&nbsp;<sapn @click="clauses(2)" v-bind:class="{'policy':policy}" style="cursor:pointer;">私隱政策</sapn></div>
                <div v-if="clause">
                <p class="p1">1)簡介</p>
                <p class="p3">1.1&nbsp;歡迎使用iMeddy。本應用程式由騰創醫療服務有限公司擁有及營運（簡稱「騰創」）。本應用程式的登入和使用以及透過本應用程式獲取的產品與服務</p>
                <p class="p2">（統稱「服務」）須遵守以下條款、細則和注意事項（簡稱「條款及細則」）。</p>
                <p class="p3">1.2&nbsp;透過使用服務，則視為閣下同意所有的條款及細則以及私隱政策，且我們或會不時對其進行更新，而恕不另行通知。閣下應該定期查閱本頁面，留意我們</p>
                <p class="p2">可能已對該等條款及細則作出的變更。請注意，倘若閣下拒絕接受該等條款及細則，閣下將無法透過我們的應用程式使用或訂購任何產品與服務。</p>
                <p class="p3">1.3&nbsp;本應用程式由騰創（簡稱「我們／本公司」）營運，我們為一間在中國香港特別行政區註冊的公司（公司編號：70230376-000-12-18-7），註冊地址</p>
                <p class="p2">位於香港香港尖沙咀梳士巴利道3號星光行19樓1908-9室。</p>
                <p class="p1">2）電腦犯罪</p>
                <p class="p3">2.1&nbsp;閣下不可故意不當使用本應用程式，包括植入病毒、特洛伊木馬、蠕蟲、運輯炸彈或其他惡意或在技術上有害的資料。閣下不可以嘗試對本應用程式、
                託管本應用程式的伺服器或與我們應用程式相關的任何其他伺服器、電腦或資料庫進行非授權登入·閣下承諾不會透過阻斷服務攻擊或分散式阻斷服務攻擊
                對本應用程式進行攻擊。若違反本條規定，閣下可能觸犯相關法規下的刑事罪行。我們會將該等違法情況上報予相關執法部門，並且將配合相關部門披露入</p>
                <p class="p2">侵者的身份。若出現該等違法情況，將立即終止閣下使用本應用程式的權利</p>
                <p class="p3">2.2&nbsp;我們將以合理程度的謹慎及技術確保本應用程式安全無虞，沒有錯誤、病毒及其他缺陷。除非由於我們未能達致上述措施所致，對於使用我們應用程式
                或從我們應用程式下載的內容或本應用程式重新導向的內容，而導致由於任何阻斷服務攻擊、病毒或任何其他惡意或在技術上對閣下的電腦、設備、數據或</p>
                <p class="p2">資料有害的軟件或資料而造成的任何損失或損害，我們概不承擔任何責任。</p>
                <p class="p1">3）許可和應用程式使用</p>
                <br />
                <p class="p2">3.1&nbsp;透過接受我們授予閣下登入和以個人身份使用本應用程式的有限許可，閣下保證自己至少已年滿16歲，或者具有簽訂具約束力合約的能力。</p>
                <p class="p3">3.2&nbsp;閣下可以在社交媒體（例如Facebook、Twitter、Instagram、Whatsapp、Line、微信或類似服務）上分享透過服務提供的內容。此許可屬於有限許可，</p>
                <p class="p2">僅可將「內容」用於該目的，並不代表任何iMeddy內容的所有權轉讓。</p>
                <p class="p3">3.3&nbsp;閣下不得：（a）在使用應用程式時違反任何適用法律或法規；（b）下達任何投機性、虚假或欺詐性訂單（否則將導致閣下的訂單被取消，且我們有權在我們認為適當時向相關部門進行舉報）；（c）冒充或虚假陳述閣下與任何個人或實體的從屬關系，包括其他人的姓名或頭像，或提供虚假的賬戶詳細資料；
                （d）利用本應用程式傷害、辱罵、騷擾、跟蹤、威脅或以其他方式冒犯其他人；（e）未經騰創明確書面同意，出於商業目的複製、複印、拷貝、出售、轉授、存取或以其他方式利用本應用程式或本應用程式任何部分；（f）未經騰創明確書面同意，擷取或利用擷取技術附上iMeddy及其關聯方（包括母公司）的任何商標、標誌或其他專有資訊（包括圖像、文本、頁面佈局或形式）；（g）利用任何數據勘探、機器人或類似資料獲取工具收集數據；（h）干擾、破壞本應用
                程式或使本應用程式承受不當的負擔；或（i）上載、發佈、傳播或以其他方式提供任何以下資料：（i）不屬於閣下的原創作品或者可能侵犯他人知識產權或其
                他權利的原創作品；（ii）屬於或者可合理預計將屬於誹謗、淫機、攻擊、威脅、辱罵、色情、粗俗、襄瀆、不雅或其他非法性質的資料，包括造成種族或宗
                教中傷，引發暴力或仇恨，或者根據種族、宗教、政治立場、民族、性別、年齡、性取向或任何身體或智力障礙可能冒犯、侮辱或羞辱他人的資料；（ii）包
                括其他人的相片或個人資料，除非已獲其同意；（iv）閣下知道或懷疑，或應合理知道或懷疑屬於虚假、誤導性或欺騙性的資料；（v）包含大量非</p>
                <p class="p2">對性、無用或重複內容的資料；或（vi）包含財務、法律、醫學或其他專業意見的資料。</p>
                <br />
                <p class="p2">3.4&nbsp;倘若閣下相信某個用戶達反了上述任何細則，請透過下述電郵與我們聯絡：alex＠drchoiceasia.com</p>
                <p class="p3">3.5&nbsp;騰創保留在不發出通知的情況下攔截或暫停本應用程式任何用戶的使用權，以及對任何用戶在本應用程式上載、發佈、傳播或以其他方式提供的任何資
                料進行修改或移除的權利。透過在本應用程式上載、傳播、發佈或以其他方式提供任何資料，閣下即已授予騰創任何形式和針對任何目的使用、複製、編輯和利</p>
                <p class="p2">用該資料的非獨家、全球性、無版稅、永久性許可，並且無條件放棄所有道德權利（定義見《版權條例》（第528章））。</p>
                <p class="p3">3.6&nbsp;對於騰創以外的任何個人在本應用程式上載、發佈、傳播或以其他方式提供的任何資料，騰創概不負責並且不承擔任何責任。騰創不認許騰創以外的任何</p>
                <p class="p2">個人提出的任何觀點、意見或聲明。</p>
                <p class="p3">3.7&nbsp;對於騰創以外的任何個人在本應用程式上載、發佈、傳播或以其他方式提供的任何資料，騰創概不負責並且不承擔任何責任·騰創不認許騰創以外的任何</p>
                <p class="p2">個人提出的任何觀點、意見或聲明。</p>
                <p class="p1">4）不可抗力</p>
                <p class="p3">4.1&nbsp;本公司於下列任何在我們合理控制能力範圍以外的事件中，並無責任履行本條款及細則下之任何義務（即「不可抗力事件」），包括但不限於以下：（i）
                罷工、停工、第三方系統或網絡故障;（ii）天災、火災、地震、風暴、洪水、流行病或其他自然災害;（ii）社會動亂、恐怖主義行為、入侵、戰爭（不論已
                宣戰與否）或戰爭的威脅或準備；（iv）無法使用鐵路、船運、飛機、汽車運輸或其他公共或私人交通工具；（v）無法使用公共或私人電訊網絡；（vi）政府的</p>
                <p class="p2">任何行動、法令、立法、法規或限制；（vii）任何運輸，郵政或其他相關的運輸罷工，故障或事故。</p>
                <p class="p3">4.2我們將在不可抗力事件發生期間暫停履行合約，且將在該段期間延長履行的時間。我們會盡切合理的努力結束該不可抗力事件，或尋找可在不可抗力事件</p>
                <p class="p2">發生期間繼續履行合約的解決方法。</p>
                <p class="p1">5）責任和免責聲明</p>
                <p class="p3">5.1&nbsp;如因閣下（或任何代表閣下的人士）所做的事、未能做到的事，或發生任何超越本公司合理控制範圍的事，而引致本公司無法履行或延遲履行本條款及細</p>
                <p class="p2">則下的責任，本公司概不負責。</p>
                <p class="p3">5.2&nbsp;本程式的材料可能不正確或存有排印上的錯誤·騰創不承認任何默示性和非明示性的保證、並且不保證本程式或素材的正確性、可靠性、完整性或及時性。騰創對其他應用程式的內容以及本程式上登載的或與本程式連接的任何事物，包括但不限於任何謬見、錯誤、遺漏、侵權、毀謗、謊言、或者其他可能
                是犯法或會引起權利主張或投訴的材料或遺漏不承擔任何責任。使用本程式和連結應用程式的材料的後果由閣下自負。該等應用程式可能隨時或定期會有變動，請不</p>
                <p class="p2">時查閱有關的材料。</p>
                <p class="p3">5.3&nbsp;騰創不保證以下事項：本程式目前或今後任何時候毫無錯誤，本程式及其服務器目前或今後任何時候沒有計算機病毒或其他有害機能，任何缺陷會被糾正，或者本應用程式或／及本程式的運作是及時、安全和不會中斷的。如果由於閣下使用該等應用程式材料，導致需要修理或更換設備或數據，騰創不負責</p>
                <p class="p2">任何由此而産生的費用。</p>
                <p class="p3">
                凡由於使用或無法使用本網程式或材料，或任何執行失敗、錯誤、遺漏、中斷、刪除、缺陷、操作或傳送的延誤、計算機病毒、通信線路失靈、線上通信的攔截、軟件或硬體問題（包括但不限於丟失數據或相容性問題）、偷竊、程式的破壊或改變所引起的，無論是因使用本程式或向本程式上載或從本程式下載
                或出版數據、文字、圖像或其他材料或資料而直接或間接造成的違約、侵權行為、疏忽或任何其他因素所引起的任何種類損害或傷害（包括但不限於意外損害、間接損害、利潤的損失、或者因失去數據或業務被中斷所造成的損害），且無論騰創是否已被告知此種損害的可能性，騰創或在本程式上提及的任何第三方在</p>
                <p class="p2">法律允許的範圍内不應承擔任何責任。</p>
                <p class="p1">6）本條款的變更</p>
                <p class="p3">6.1&nbsp;騰創保留一切權利，自行決定隨時更改、修改、增加或刪除此等條款的任何部分，而無須通知閣下·騰創會將條款變更刊載至本應用程式上，此等條款的底</p>
                <p class="p2">部日期亦會更新為最後修改日期。騰創建議閣下定期閱覽此等條款，留意適用於閣下對應用程式使用及閣下產品付運的最新條款。</p>
                <br />
                <p class="p2">6.2&nbsp;如閣下在條款變更後或更新後繼續登入及使用應用程式，即代表閣下接受新條款。閣下有責任定期閱覽本應用程式，留意此等條款是否有變。</p>
                <p class="p3">6.3&nbsp;如任何此等條款或合約內的任何條款被權威機構判定為無效、違法或不可執行（不論任何程度），此等條款、細則或規定將依據相應程度從其他條款、細</p>
                <p class="p2">則及規定中分離，其他部分將繼續在法律允許的最大範圍內有效。</p>
                <br />
                <p class="p2">6.4&nbsp;在未經本公司書面同意前，不可變更或修改本條款及細則。</p>
                <p class="p1">7）法律和司法管轄權</p>
                <p class="p3">7.1&nbsp;本條款及細則受香港法律監管。任何因使用本應用程式或本合約或與之相關而引起的爭議均受香港法院專屬管轄。請注意，此等條款不會影響閣下作為消</p>
                <p class="p2">費者的法定權利。</p>
                <p class="p1">8）一般資料</p>
                <br />
                <p class="p2">8.1&nbsp;倘若接受本條款及細則並繼續使用本應用程式，即代表閣下已閱讀並理解本條款及細則，並同意遵循和受其約束。</p>
                <p class="p3">8.2&nbsp;本條款及細則（連同私隱政策）列出我們之間就應用程式的使用及產品的銷售、購買、付運及使用的完整協議。本條款及細則凌駕於所有就本文所述事項</p>
                <p class="p2">而作出的協議。除非經我們雙方書面同意，否則此等條款的變更對我們並無約束力。</p>
                <p class="p1">9）「iMeddy心意」服務</p>
                <p class="p4">9.1&nbsp;簡介</p>
                <p class="p3">「iMeddy心意」服務由《iMeddy》所提供，為用戶提供線上向香港慈善團體捐贈及援助平台服務·閣下明 白及同意「iMeddy心意」服務可能包括廣告，而</p>
                <p class="p2">這些廣告是「iMeddy心意」服務的一部份，閣下需要自行評估經由「iMeddy心意」服務所接收的任何資訊及內容的準確性及可靠性。</p>
                <p class="p4">9.2&nbsp;於「iMeddy心意」捐款予登記慈善團體</p>
                <p class="p3">如欲捐款予「iMeddy心意」服務上的登記慈善團體，須先註冊為iMeddy會員（下稱「會員」）並同意設立《香港iMeddy》用戶賬戶的相關使用條款。《iMeddy》亦有權在閣下設立會員賬戶後拒絕閣下的登記或終止閣下的賬號。會員一旦開始使用，即代表會員已閱讀及同意接受本條款、《iMeddy》
                《個人資料（私隱）收集聲明》，及授予《iMeddy》以及參與服務營運的所有其他人員，傳播、監控、檢索、存取和使用會員與本服務運作有關的資料以及向會員提供服務的權利。如果會員不同意上述，會員應立即終止其《iMeddy》個人賬戶，並停止使用《iMeddy》網站、流動應用程式（App）、網頁及服務。</p>
                <p class="p4">9.3&nbsp;會員遺失帳戶或被未獲授權使用</p>
                <p class="p3">每個會員都要對其帳戶中的所有活動和事件負全責。閣下有責任保護其《iMeddy》個人帳戶資料（用戶名稱及登入密碼），及其電郵地址及密碼安全，及承諾不向第三者透露其個人賬戶資料（用戶名稱、電郵地址及密碼）。如果閣下未有保管好自己的登入名稱和密碼安全，將需要自行承擔全部責任。任何情況下，如任何非會員本人之人士以任何方式透過會員之用戶名稱、電郵地址及密碼登入《iMeddy》網站及／或流動應用程式（App），而導致的一切責任及</p>
                <p class="p2">損失與《iMeddy》無關，《iMeddy》不會負上任何責任。</p>
                <p class="p3">如閣下永久遺失登入會員用戶名稱及登入密碼，於舊賬戶內的全部記錄及資料均不能夠轉移至新賬戶，而於舊賬戶內的記錄及資料將會被註銷，閣下亦不能向《iMeddy》索還舊賬戶內的記錄及資料。於任何情況下，閣下的《iMeddy》個人賬戶在非會員本人使用的期間所蒙受之任何損失，《iMeddy》一概不負責</p>
                <p class="p4">9.4&nbsp;用戶經「iMeddy心意」捐款予登記慈善團體</p>
                <p class="p3">使用「iMeddy心意」服務的捐款者必須為iMeddy會員，並只接受使用「iCoin」服務捐款，而捐款將經「iCoin」服務直接由閣下所選取的登記慈善團體收取及處理。如閣下就該慈善捐款需要電子收據，需於確認捐款前提出，並於表格內填寫所需的資料作《iMeddy》及該慈善團體處理捐款之用途；如閣下未有
                填寫所需的資料，《iMeddy》有機會未能協助慈善團體向閣下發出電子收據。電子收據會由「iMeddy心意」服務上的登記慈善團體，經「iMeddy心意」服務，發送到閣下於iMeddy會員賬戶內所提供之電郵，須受電子收據內的條款及細則約束，「iMeddy心意」服務惟並不會發放實體收據；如果閣下未有於
                iMeddy會員賬戶內提供電郵地址，則有機會未向閣下發出電子收據。閣下於成功捐款後，無論支持之項目最終是否完成或成功執行，均不得取消或減少捐款，或要求退款，而閣下的捐款會由受助慈善團體全權處理，與《iMeddy》無關·就「iMeddy心意」服務上的登記慈善團體於其版面所作出的保證或陳述，</p>
                <p class="p2">《iMeddy》並不作任何保證或擔保。</p>
                <p class="p3">
                如果登記慈善團體於「iMeddy心意」服務所宣傳籌款之服務或項目的交付發生重大延誤，或者服務被終止，或籌款項目創建之登記慈善團體出於任何原因無法或將不會按照允諾交付服務，或所交付服務與所述服務無關，上述一概責任與「iMeddy心意」及《iMeddy》無關·就捐款相關事宜，捐款者需要直接與
                </p>
                <p class="p2">該慈善團體處理相關問題或查詢。</p>
                <br />
                <p class="p2">若閣下於使用「iMeddy心意」服務出現技術問題，請以透過以下電郵通知「iMeddy心意」：iMeddyheart＠hkiMeddy.com。</p>
                <p class="p3">閣下了解並同意，《iMeddy》依據法律或規定的要求，或基於誠信為了以下目的之合理必要範圍內，認定必須將閣下使用「iMeddy心意」服務記錄加以</p>
                <br />
                <p class="p2">或揭露時，得加以保存及揭露：</p>
                <br />
                <p class="p2">保存遵守任何法律程序及相關要求；</p>
                <br />
                <p class="p2">執行本條款；</p>
                <br />
                <p class="p2">回應任何懷疑侵害第三人權利之主張及相關請求；</p>
                <br />
                <p class="p2">或保護《iMeddy》、其使用者及公眾之權利、財產或個人安全。</p>
                <br />
                <p class="p3"> 閣下了解「iMeddy心意」服務之技術處理及傳送，可能（a）經由各個網路加以傳送，且（b）為了符合及配合連線網路或裝置之技術要求而進行改變。</p>
                                <p class="p4">9.5&nbsp;彌償</p>
                                <p class="p3">倘若閣下違反本條款、或閣下涉嫌侵害其他人任何權利，因而衍生或導致任何第三人向《iMeddy》及／或其關聯公司、控股公司、主管、代理人、或其他夥</p>
                <p class="p2">伴及員工提出索賠或請求，閣下同意彌償一切損失（包括但不限於律師費、行政費、法庭費用等所有費用），並使其免受損害。</p>
                <br />
                <p class="p2">9.6&nbsp;服務轉售之禁止</p>

                <p class="p3">閣下同意不進行複製、轉錄、拷貝、出售、轉售或作任何商業目的使用「iMeddy心意」服務、《iMeddy》網站、及／或流動應用程式（App）之任何部</p>
                <p class="p2">份。</p>
                <p class="p4">9.7&nbsp;關於使用及儲存之一般措施</p>
                <p class="p3">閣下同意《iMeddy》有權就「iMeddy心意」服務不時訂立一般措施及限制，包含但不限於公告內容、特定版面規條、捐款方式及形式·若《iMeddy》將任何</p>
                <p class="p2">內容刪除或未予儲存，閣下同意《iMeddy》毋須承擔任何責任。</p>
                <p class="p3">閣下亦同意，《iMeddy》有權關閉任何其認為長時間未有使用iMeddy會員賬戶，而毋須預先通知。閣下也同意，《iMeddy》有權依其自行之考量，不論有</p>
                <p class="p2">否通知閣下，隨時變更這些一般措施及限制。</p>
                                <p class="p4">9.8&nbsp;「iMeddy心意」服務之修改</p>
                                <p class="p3">《iMeddy》有權於任何時間毋須預先通知暫時或永久修改或終止「iMeddy心意」服務（或其任何部分）·「iMeddy心意」服務任何修改、暫停或終止，閣下</p>
                <p class="p2">同意《iMeddy》對閣下和任何第三人均不承擔責任。</p>
                <p class="p4">9.9 「iMeddy心意」服務之終止</p>
                <p class="p3">閣下同意《iMeddy》得基於其自行之考量，因任何理由，包含但不限於閣下長時間未有使用閣下的iMeddy會員賬戶、或《iMeddy》認為閣下已經違反本條款及精神、或《iMeddy》有合理理由懷疑閣下任何的登記資料失實、不準確、過時或不完整，《iMeddy》有權暫停或終止閣下的iMeddy會員賬戶或</p>
                <p class="p2">「iMeddy心意」服務之使用，無需事先通知閣下，《iMeddy》對閣下或任何第三人均不承擔責任。</p>
                <p class="p4">9.10與廣告商進行之交易</p>
                <p class="p3">閣下於「iMeddy心意」或經由「iMeddy心意」登記慈善團體或其他合作伙伴進行通訊或商業往來，或參與促銷活動，包含相關商品或服務之付款及交付，以及該等交易其他任何相關條款、條件、保證或陳述，完全為閣下與夥伴機構之間之行為，《iMeddy》並非該等合約之一方，而僅是提供一個平台·閣下
                不得以任何方式依賴《iMeddy》，以判定透過「iMeddy心意」、《iMeddy》網站或流動應用程式（App）上被提及有關此等產品及／或服務之任何資訊的準確性及可靠性。閣下承諾須自行對上述第三方進行閣下認為必要或合適之任何調查。如會員因接納由夥伴機構之服務而蒙受任何不便或損失，《iMeddy》亦不</p>
                <p class="p2">會負責任何損失，會員對《iMeddy》不具有任何追究權力。</p>
                <p class="p4">9.11 連结</p>
                <p class="p3">「iMeddy心意」、本網站及／或流動應用程式（App）載有與萬維網其他網站、網頁或資源的連接，該等連接並非由《iMeddy》保持或控制，因此《iMeddy》對此概不承擔任何責任。閣下了解並同意，該等外部網站或資源是否可供利用，《iMeddy》不予負責。當閣下啟動任何該等連接之風險均由閣
                下自行承擔，《iMeddy》概不對載於或從該等網站或網頁獲取之任何內容、廣告、產品、服務或其他資料承擔內容」責任·閣下進一步同意，因使用或信賴存在或經由該等網站或網頁之任何內容、廣告、產品、服務或其他資料所生或據稱所生之任何損害或損失，《iMeddy》不負任何直接或間接之責任。</p>
                <p class="p4">9.12免責聲明</p>
                <br />
                <p class="p2">閣下明確了解並同意：</p>
                <br />
                <p class="p2">閣下自行承擔使用或不使用「iMeddy心意」服務的風險。</p>
                <br />
                <p class="p2">刊載於「iMeddy心意」服務、本網站、流動應用程式（App）、網頁或服務的內容、資料均以「現況」及「現有」形式提供。</p>
                <p class="p3">《iMeddy》不保證以下事項：（1）「iMeddy心意」服務將符合閣下的要求，（i）「iMeddy心意」服務不受干擾、及時提供、安全可靠或免於出錯，（ii）由「iMeddy心意」服務之使用而取得之結果為正確或可靠，（iv）閣下透過或經由「iMeddy心意」服務購買或取得之任何產品、服務、資訊或其他資
                </p>
                <p class="p2">料或接收的訊息或訂立的交易將符合閣下的期望。</p>
                <p class="p3">
                是否經由「iMeddy心意」服務之使用下載或取得任何資料應由閣下自行考量且自負風險，及因該等任何資料之下載而導致閣下電腦系統之任何損壞或資料流
                </p>
                <p class="p2">失，閣下應負完全責任。</p>
                <br />
                <p class="p2">閣下經由「iMeddy心意」服務取得之建議和資訊，無論其為書面或口頭，絕不構成《iMeddy》之任何保證。</p>
                <br />
                <p class="p2">有關任何操作或系統錯誤引至的資料損失、或傳輸錯誤，《iMeddy》恕不負責。</p>
                <p class="p3">任何未經授權使用或變更閣下傳輸的資料或個人識別資料、或任何第三方就任何服務之聲明或行為而引至之任何後果、損害或損失，《iMeddy》不負任何直</p>
                    <p class="p2">接或間接之責任。</p>
                <p class="p3">
                除非另有其他明文規定，修訂、增加、減少或強化目前「iMeddy心意」服務的任何功能均受到本條款之規範。《iMeddy》保留權利無須事先通知閣下，暫停全部或部分服務以進行維修、保養、更新或其他《iMeddy》認為適當的動作·與「iMeddy心意」服務有關的第三方費用（例如網路服務供應商可能會就使</p>
                <p class="p2">用流動數據或Wi-Fi收取費用），閣下需自行負責。此外，閣下必須自行提供「iMeddy心意」所需之一切必要裝備及為此負責</p>
                <p class="p4">9.13 責任限制</p>
                <p class="p3">閣下明確了解並同意，基於以下原因而造成之損失，包括但不限於利潤、商譽、使用、資料損失或其他無形損失，《iMeddy》不承擔任何直接、間接、附
                </p>
                    <p class="p2">帶、特別、衍生性或懲罰性賠償：</p>
                    <br />
                <p>
                「iMeddy心意」線上論壇服務之使用或無法使用；經由或透過「iMeddy心意」購買或取得之任何商品、資料、資訊或服務，或接收之訊息，或進行之交易所衍生之替代商品及服務之購買成本；閣下的傳輸或資料遭到未獲授權的存取修改、或發布；「iMeddy心意」中任何第三人之聲明或行為；或「iMeddy心</p>
                <p class="p2">意」服務（在本條款中以其他方式明確提供的除外）其他相關事宜。</p>
                <p class="p4">9.14 知識產權</p>
                <p class="p3">閣下同意將不會使用「iMeddy心意」、《iMeddy》網站或流動應用程式（App）侵犯他人的知識產權。倘《iMeddy》收獲任何涉及會員及登記慈善團體
                使用「iMeddy心意」服務、《iMeddy》網站或流動應用程式（App）侵犯他人版權的通知或投訴，《iMeddy》有權對此進行調查，以及作出《iMeddy》認</p>
                <p class="p2">為合適的處理方法，而毋須預先通知·若閣下認為閣下的知識產權遭到侵犯，請聯絡「iMeddy心意」：iMeddyenquiry＠tc-doctor.com</p>
                <p class="p1">10）反饋</p>
                <p class="p3">10.1我們歡迎閣下提出建議、構思、意見、問題或其他資訊，但内容不可為非法、淫穢、威脅、誹謗、侵犯私隱、侵犯知識產權或以其他方式傷害第三方或
                令人反感，而且不含軟件病毒、政治活動、商業推銷、連鎖信件、群發郵件或任何形式的「垃圾郵件」。閣下不可使用虚假電郵地址、冒充任何個人或實體，或</p>
                <p class="p2">以其他方式誤導任何內容的來源。</p>
                <p class="p3">10.2閣下代表及保證閣下擁有或控制閣下提交內容的所有權利、内容正確、使用閣下提交內容不會違反本使用條款、不會傷害任何人士或機構，並會就所有
                由閣下提交內容而引起的索償，向本公司或其成員公司作出補償·閣下知悉並同意，我們有權但無責任監管及編輯或移除任何活動或內容。對閣下或其他第三者</p>
                <p class="p2">通過本應用程式分享的任何內容，本公司概不負責.</p>
                <br />
                <p class="p2">10.3 如對本條款及細則有進一步問題，請聯絡：</p>
                <br />
                <p class="p2">Tech Creation Medical Service Limited</p>
                <br />
                <p class="p2">香港尖沙咀梳士巴利道3號星光行19樓1908-9室</p>
                <br />
                <p class="p2">電話：＋852&nbsp;5973&nbsp;0064／＋852&nbsp;9320&nbsp;0052</p>
                <br />
                <p class="p2">電郵：enquiry＠tc-doctor.com</p>
                <br />
                <p class="p2">香港註冊（公司註冊編號70230376-000-12-18-7）</p>
                <br />
                <p class="p2">最後更新日期-2020年09月23日</p>
                </div>
                <div v-if="policy">
                    <p class="p1">私隱政策</p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clause:true,
            policy:false,
        };
    },
    methods: {
        clauses(val){
            if(val === 1){
                this.clause = true;
                this.policy = false;
            }else{
                this.clause = false;
                this.policy = true;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.p1{
    text-align:center;
    font-weight: bold;
    margin-top:25px;
}
.clauses{
    text-decoration:underline;
    font-weight: 600;
}
.policy{
    text-decoration:underline;
    font-weight: 600;
}
.p4{
    text-align:center;
    margin-top:25px;
}.p2{
    text-align:center;
    margin-top:5px;
}
.p3{
    margin-top:25px;
}
// p{
//     margin-top:25px;
// }
.container {
    min-height: calc(100vh - 48px);
    background-size: cover;
    background-position: center;
    @include fr-c-c;
}
main {
    position: relative;
    background-color: $white;
    width: 80vw;
    max-width: 1175px;
    min-height: 60vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 32px 32px 88px;
    gap: 16px;
    .title {
        color: $green-500;
        font-size: 24px;
        margin-bottom: 16px;
    }
    .info-1,
    .info-2 {
        @include fc-s-st;
    }
    .confirm-button {
        position: absolute;
        right: 32px;
        bottom: 32px;
        width: 150px;
    }
}
.categories {
    .radio-container {
        @include fr-b-c;
        row-gap: 16px;
        flex-wrap: wrap;
        .category-radio {
            width: 45%;
        }
    }
}
.amounts {
    &:first-child {
        grid-column: 1 / 3;
        .radio-container .amount-radio {
            width: 23%;
        }
    }
    .radio-container {
        @include fr-b-c;
        row-gap: 16px;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .amount-radio {
            width: 45%;
        }
    }
}
.payment-method {
    @include fc-s-s;
    row-gap: 16px;
}
@media screen and (max-width: 600px) {
    main {
        width: 100%;
        grid-template-rows: auto auto auto;
        grid-template-columns: auto;
        .amounts:first-child {
            grid-column: auto;
            .radio-container {
                .amount-radio {
                    width: 45%;
                }
            }
        }
    }
}
</style>